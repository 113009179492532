<template>
  <div>
    <b-row class="business-card-type-select">
      <b-col
        v-for="businessCardsType in businessCardsTypes"
        :key="businessCardsType.key"
        cols="6"
        md="3"
        class="d-flex flex-wrap align-items-center justify-content-center"
        style="gap: 1em;"
        @click="selectCardType(businessCardsType)"
      >
        <div
          :ref="`business-card-type-${businessCardsType.id}`"
          v-b-tooltip.hover.left="businessCardsType.description"
          class="business-card-type border border-primary rounded p-2"
          :class="[
            {'selected' : selectedCardTypeId === businessCardsType.id},
            {'disabled': $isNotEmpty(businessCardsType.deactivated_at)},
            {'custom-overlay-danger custom-overlay-6': $isNotEmpty(businessCardsType.deactivated_at)}
          ]"
        >
          <div class="d-flex flex-wrap justify-content-center">
            <img
              class="fade-in-image"
              fluid
              :src="businessCardsType.image || $defaultCardTypeImage"
              width="90"
              height="90"
            >
            <h4 class="text-primary text-center m-0 p-0 pt-1">{{ businessCardsType.name }}</h4>
            <h6 class="text-danger text-center m-0 p-0 pt-1 text-bolder text-unavailable">
              <b class="">
                {{ $isNotEmpty(businessCardsType.deactivated_at) ? 'UNAVAILABLE' : ''}}
              </b>
            </h6>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      businessCardsTypes: [],
      selectedCardTypeId: null,
    }
  },
  created() {
    this.getBusinessCardsTypes()
  },
  methods: {
    getBusinessCardsTypes() {
      this.$http
        .get(`/api/business-cards-types`)
        .then(response => {
          const { success, message, output } = response.data
          this.businessCardsTypes = output
        })
        .catch(error => {
          this.$handleResponseError(error)
        })
    },
    selectCardType(businessCard) {
      if (this.$isEmpty(businessCard.deactivated_at)) {
        this.$emit('selected-card-type-id', businessCard.id)
        this.selectedCardTypeId = businessCard.id
      }
      // this.$refs[`business-card-type-${id}`][0].classList.value += (' selected')
      // this.$refs[`business-card-type-${id}`][0].classList.value += (' selected')
    }
  },
}
</script>

<style lang="scss" scoped>
.business-card-type-select {
  max-height: 55vh;
  overflow-y: auto;
}

.business-card-type {
  // width: 8em;
  height: 12em;
  margin-block: 0.5em;
  margin-inline: 0.3em;

  &:hover{
    background-color: #bfa25e;

    h3, h4 {
      color: white !important;
    }
  }
}

.selected {
  background-color: #bfa25e;

  h3, h4 {
    color: white !important;
  }
}
.disabled {
  &:hover{
    background-color: grey;

    h3, h4 {
      color: black !important;
    }
  }
  .text-unavailable {
    color: #EA5455 !important;
  }
}

</style>
