/* eslint-disable import/prefer-default-export */
import businessCardForm from '../forms/index.vue'
import businessCardFormType from '../components/business-card-form-type.vue'

export const businessCardFormMixins = {
  components: {
    businessCardForm,
    businessCardFormType,
  },
  data() {
    return {
      popupBusinessCardForm: false,
      formType: 'create',
      cardId: null,
      showPaymentStep: null,
    }
  },
  methods: {
    showBusinessCardForm(formType, cardId = null, showPaymentStep = true) {
      this.popupBusinessCardForm = true
      this.formType = formType
      this.cardId = cardId
      this.showPaymentStep = showPaymentStep
    },
  }
}