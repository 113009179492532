<template>
  <div>
    <form-wizard
      ref="refBusinessCardWizard"
      v-model="activeTab"
      :title="null"
      :subtitle="null"
      color="#bfa25e"
      step-size="sm"
      finish-button-text="Submit"
      back-button-text="Previous"
      @on-complete="postBusinessCard()"
    >
      <!-- @on-complete="businessCard.stripe_payment_status === 'paid' ? postBusinessCard() : $refs.stripePlugin.pay()" -->
      <!-- Form: Card Type -->
      <tab-content
        v-if="formType === 'create'"
        title="Choose card Type"
        :before-change="canGoAfterCardType"
      >
        <business-card-form-type
          @selected-card-type-id="selectedCardTypeId"
        />
      </tab-content>

      <!-- Form: Card Finishing -->
      <tab-content
        v-if="formType === 'create' && !$isEmpty(selectedBusinessCardType.finishings)"
        :lazy="true"
        title="Choose card finishing"
        :before-change="canGoAfterCardFinishing"
      >
        <business-card-form-type-finishing
          v-if="businessCard.card_type_id"
          :card-type-id="businessCard.card_type_id"
          @selected-card-finishing-id="selectedCardTypeFinishingId"
        />
      </tab-content>

      <!-- Form: Card Details -->
      <tab-content
        title="Card Details"
        :before-change="createCard"
        :lazy="true"
      >
        <b-overlay
          :show="isLoading"
          rounded="sm"
        >
          <b-row>
            <b-col
              cols="12"
              :md="formType == 'edit' ? 12 : 6"
              class="d-flex flex-wrap align-items-center justify-content-center"
            >
              <div class="flip-card card-display-container">
                <div
                  ref="refsFlipCardInner"
                  class="flip-card-inner"
                  :class="{'flip-back' : displayCardBackSide}"
                >
                  <div class="flip-card-front">
                    <img
                      ref="refFrontImageImg"
                      class="cursor-pointer"
                      fluid
                      :src="businessCard.front_image || $defaultCardFrontImage"
                      width="300"
                      height="200"
                      data-placement="left"
                      data-toggle="tooltip"
                      title="Click to flip the card"
                      @click="flipCard()"
                    >
                  </div>
                  <div class="flip-card-back">
                    <img
                      ref="refBackImageImg"
                      class="cursor-pointer"
                      fluid
                      :src="businessCard.back_image || $defaultCardBackImage"
                      width="300"
                      height="200"
                      data-placement="left"
                      data-toggle="tooltip"
                      title="Click to flip the card"
                      @click="flipCard()"
                    >
                  </div>
                </div>
              </div>
            </b-col>

            <b-col
              cols="12"
              md="6"
              class="d-flex flex-wrap align-items-center justify-content-center"
            >
              <b-button
                v-if="formType != 'edit'"
                @click="$refs.uploadFrontImageInput.click()"
              > Upload Front Image </b-button>
              <input
                ref="uploadFrontImageInput"
                type="file"
                class="hidden"
                accept="image/*"
                @change="updateFrontImage"
              >

              <b-button
                v-if="formType != 'edit'"
                class="mt-1 mt-md-0"
                @click="$refs.uploadBackImageInput.click()"
              > Upload Back Image </b-button>
              <input
                ref="uploadBackImageInput"
                type="file"
                class="hidden"
                accept="image/*"
                @change="updateBackImage"
              >
            </b-col>

            <b-row
              v-if="formType == 'edit'"
              class="w-100 m-0 mx-2 p-1"
            >
              <b-col
                v-if="$isNotEmpty(businessCard.card_type)"
                cols="3"
              >
                Type:
                <img
                  v-b-tooltip.hover.top="businessCard.card_type.name"
                  class="fade-in-image"
                  fluid
                  :src="businessCard.card_type.image || $defaultCardTypeImage"
                  width="40"
                  height="40"
                >
              </b-col>

              <b-col
                v-if="$isNotEmpty(businessCard.card_finishing)"
                cols="3"
              >
                Finishing:
                <img
                  v-b-tooltip.hover.top="businessCard.card_finishing.name"
                  class="fade-in-image"
                  fluid
                  :src="businessCard.card_finishing.image || $defaultCardTypeFinishingImage"
                  width="40"
                  height="40"
                >
              </b-col>
            </b-row>

            <hr v-if="formType == 'create'">

            <b-row
              class="w-100 mx-2 mt-1"
            >
              <b-form-group class="w-100" label="Type of landing page" v-slot="{ ariaDescribedby }">
                <div class="d-flex flex-row">
                  <b-form-radio v-model="businessCard.landing_page_type" :aria-describedby="ariaDescribedby" name="some-radios" value="static">
                    Static &nbsp;
                    <feather-icon
                      v-b-tooltip.hover.top="`Redirect page provided by Digital Business Card when the card is scanned`"
                      icon="InfoIcon"
                      size="14"
                    />
                  </b-form-radio>
                  <b-form-radio class="ml-2" v-model="businessCard.landing_page_type" :aria-describedby="ariaDescribedby" name="some-radios" value="dynamic">
                    Dynamic &nbsp;
                    <feather-icon
                      v-b-tooltip.hover.top="`Redirect to your website / link when the card is scanned`"
                      icon="InfoIcon"
                      size="14"
                    />
                  </b-form-radio>
                </div>
              </b-form-group>
            </b-row>

            <b-row
              v-if="businessCard.landing_page_type === 'dynamic'" 
              class="w-100 mx-2 p-1 border border-primary rounded"
            >
              <b-col
                cols="12"
              >
                <h4> Current Active Profile: {{ businessCard.active_link || '-' }} </h4>
              </b-col>

              <b-col
                cols="12"
                class="mt-1"
              >
                <h6 class="text-weight-bolder"> Link </h6>
                <div
                  class="mx-1 d-flex flex-wrap justify-content-start"
                  style="gap: 1em 5em;"
                >
                  <div
                    v-for="index in businessCard.dynamic_link.length"
                    :key="index.key"
                    class="d-flex align-items-center"
                  >
                    <label class="switch">
                      <input
                        :ref="`refActiveLinkCheckbox-${index -1}`"
                        :checked="!$isEmpty(businessCard.active_link) && businessCard.dynamic_link[index -1] === businessCard.active_link"
                        type="checkbox"
                        @click="changeActiveLink($event, businessCard.dynamic_link[index -1])"
                      >
                      <span class="slider round" />
                    </label>

                    <div
                      class="dynamic-link-input pl-1"
                    >
                      <b-form-input
                        v-model="businessCard.dynamic_link[index -1]"
                        placeholder="Please input a link"
                        :state="$validateUrl(businessCard.dynamic_link[index -1])"
                        @input="syncActiveLink(`refActiveLinkCheckbox-${index -1}`, businessCard.dynamic_link[index -1])"
                      />
                    </div>
                    <div
                      class="m-0 p-0 pl-1 text-danger cursor-pointer"
                      @click="removeDynamicLink(index -1)"
                    >
                      <feather-icon
                        icon="TrashIcon"
                        class="h-100"
                      />
                    </div>
                  </div>
                </div>

                <div
                  class="mt-1"
                >
                  <b-button
                    @click="addAnotherDynamicLink()"
                  >
                    Add another link
                  </b-button>
                </div>
              </b-col>
            </b-row>
 
            <b-row
              v-if="businessCard.landing_page_type === 'static'"
              class="w-100 mx-2 p-1 border border-primary rounded"
            >
              <b-col cols="3">
                <div class="mt-1">
                  <h6 class="text-weight-bolder">User Image</h6>
                  <div
                    @click="$refs.uploadStaticPageUserImageInput.click()"
                  >
                    <img
                      ref="refStaticPageUserImageImg"
                      class="cursor-pointer"
                      fluid
                      :src="businessCard.static_page_details && $isNotEmpty(businessCard.static_page_details.user_image) ? businessCard.static_page_details.user_image : $defaultCardStaticPageUserImage"
                      width="100"
                      height="100"
                      data-placement="left"
                      data-toggle="tooltip"
                      title="Click to upload an image to display on the static page"
                    >
                  </div>
                  <input
                    ref="uploadStaticPageUserImageInput"
                    type="file"
                    class="hidden"
                    accept="image/*"
                    @change="updateStaticPageUserImage"
                  >
                </div>
              </b-col>

              <b-col>
                <div class="mt-1">
                  <h6 class="text-weight-bolder">Display Name</h6>
                  <b-form-input
                    v-model="businessCard.static_page_details.display_name"
                    :state="$isNotEmpty(businessCard.static_page_details.display_name)"
                    class="mx-1"
                    placeholder="Please input a your name"
                  />
                </div>

                <div class="mt-1">
                  <h6 class="text-weight-bolder">Links To Display</h6>

                  <div
                    class="mx-1 d-flex flex-wrap w-100"
                    style="gap: 1em 2em;"
                  >
                    <div
                      v-for="index in businessCard.static_page_details.links.length"
                      :key="index.key"
                      class="d-flex flex-row w-100"
                    >
											<div style="width: 45%;">
												<b-form-group
													label="Reflected Name"
													:label-for="`input-reflected_name-${index}`"
												>
													<b-form-input
														:id="`input-reflected_name-${index}`"
														v-model="businessCard.static_page_details.links[index -1].reflected_name"
														placeholder="Please input a title for the link"
														style="min-width: 10rem;"
													/>
												</b-form-group>
											</div>

											<div style="width: 45%;">
												<b-form-group
													label="Redirected Link"
													:label-for="`input-redirected_link-${index}`"
												>
													<b-form-input
														:id="`input-redirected_link-${index}`"
														v-model="businessCard.static_page_details.links[index -1].redirected_link"
														placeholder="Please input a link to your website"
														:state="$validateUrl(businessCard.static_page_details.links[index -1].redirected_link)"
														style="min-width: 10rem;"
													/>
												</b-form-group>	

											</div>

											<div class="d-flex align-items-center" style="width: 10%;">
												<div
													class="m-0 p-0 pl-1 text-danger cursor-pointer w-100"
													@click="removeStaticPageLink(index -1)"
												>
													<feather-icon
														icon="TrashIcon"
														class="h-100"
													/>
												</div>
											</div>
                    </div>
                  </div>

                  <div
                    class="mt-1"
                  >
                    <b-button
                      @click="addAnotherStaticPageLink()"
                    >
                      Add another link
                    </b-button>
                  </div>
                </div>
              </b-col>
            </b-row>

          </b-row>
        </b-overlay>
      </tab-content>

      <!-- Form: Payment -->
      <tab-content
        v-if="showPaymentStep"
        :lazy="true"
        title="Payment"
      >
        <div class="d-flex align-items-center justify-content-center">
          <b-overlay
            variant="white"
            :show="isLoading && $isNotEmpty(businessCard.id)"
            spinner-variant="primary"
            blur="0"
            rounded="sm"
          >
            <div
              v-if="['paid'].includes(businessCard.stripe_payment_status)"
            >
              <h3> Payment Succedded </h3>
            </div>

            <stripe-checkout
              v-else-if="$isNotEmpty(businessCard.id) && !['paid'].includes(businessCard.stripe_payment_status)"
              ref="stripePlugin"
              :create-session-endpoint="`/api/business-cards/${businessCard.id}/create-session`"
							:parent-is-loading="parentIsLoading"
              @isLoading="isLoading = $event"
            />
          </b-overlay>
        </div>
      </tab-content>
    </form-wizard>
  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'


import StripeCheckout from '../../stripe/stripe-checkout.vue'
import businessCardFormType from '../components/business-card-form-type.vue'
import businessCardFormTypeFinishing from '../components/business-card-form-type-finishing.vue'

export default {
  components: {
    businessCardFormType,
    businessCardFormTypeFinishing,
    StripeCheckout,

    FormWizard,
    TabContent,
  },
  props: {
    cardId: {
      type: [String, Number],
      default: null,
    },
    initialFormType: {
      type: String,
      default: 'create',
    },
    showPaymentStep: {
      type: Boolean,
      default: true,
    },

		parentIsLoading: {
			type: Boolean,
			default: false,
		}
  },
  watch: {
    formType(val, oldVal) {
      //
    },
  },

  data() {
    return {
      isLoading: false,
      dontReload: true,
      formType: 'create',
      businessCard: {
        landing_page_type: 'static',
        static_page_details: {
          user_image: null,
          display_name: null,
          links: [],
        },
        active_link: '',
        static_link: 'networking.com/test',
        dynamic_link: [],
        front_image: this.defaultCardImage,
        back_image: this.defaultCardImage,
        card_type_id: null,
        card_finishing_id: null,
      },
			staticPageLinkValueTemplate: {
				reflected_name: '',
				redirected_link: '',
			},

			
      activeTab: 0,
      selectedBusinessCardType: {
        finishings: [],
      },
      displayCardBackSide: false,

      formData: new FormData(),

      stripeReturnUrl: null,

      pk: 'pk_test_51MGaTvECCuXqKKn7TMyv31CIXXl0cj6tGofNpsvnYvmCnlEVDoF2fkmtCrwMf5Mrlap1OtUrZsObwWyFgDkMSZJ300UQj5oT4i',
      elementsOptions: {
        appearance: {}, // appearance options
      },
      confirmParams: {
        return_url: null, // success url
      },

			firstTimeCreate: true, // workaround to prevent updating after creating
    }
  },
  created() {
    if (this.$isNotEmpty(this.cardId)) {
      this.formType = 'edit'
    }

    if (this.formType === 'edit') {
      this.getBusinessCard(this.cardId)
			this.firstTimeCreate = false
    }

    if (this.$isEmpty(this.businessCard.dynamic_link)) {
      this.businessCard.dynamic_link = ['']
    }

    if (this.$isEmpty(this.businessCard.static_page_details.links)) {
      this.businessCard.static_page_details.links = [{ ...this.staticPageLinkValueTemplate }]
    }
  },
  mounted() {
    // this.createCard()
  },
  methods: {
    getBusinessCard(id) {
      this.isLoading = true

      this.$http
        .get(`/api/business-cards/${id}`)
        .then(response => {
          const { success, message, output } = response.data
          this.businessCard = output

          if (this.$isEmpty(this.businessCard.static_page_details)) {
            this.$set(this.businessCard, 'static_page_details', {
              user_image: null,
              display_name: null,
              links: [],
            })
          }
          if (this.$isEmpty(this.businessCard.static_page_details.links[0])) {
            this.businessCard.static_page_details.links = [{ ...this.staticPageLinkValueTemplate }]
          }


          const route = this.$router.resolve({ name: 'business-cards-payment-success', params: { id: this.businessCard.id } }).href
          this.stripeReturnUrl = new URL(route, window.location.origin).href
          this.confirmParams.return_url = new URL(route, window.location.origin).href

          this.isLoading = false

        })
        .catch(error => {
          this.$handleResponseError(error)
          this.isLoading = false
        })
    },
    getBusinessCardType(id) {
      this.isLoading = true

      this.$http
        .get(`/api/business-cards-types/${id}`)
        .then(response => {
          const { success, message, output } = response.data

          this.selectedBusinessCardType = output
          this.isLoading = false
        })
        .catch(error => {
          this.$handleResponseError(error)
          this.isLoading = false
        })
    },
    postBusinessCard() {
      return new Promise((resolve, reject) => {
        this.isLoading = true

        const noDups = new Set(this.businessCard.dynamic_link);
        if (this.businessCard.dynamic_link.length !== noDups.size) {
          this.isLoading = false
          this.dontReload = true
          this.$toastDanger(null, 'There are duplicated dynamic links')

          reject()
          return
        }

        const noStaticPageLinkDups = new Set(this.businessCard.static_page_details.links);
        if (this.businessCard.static_page_details.links.length !== noStaticPageLinkDups.size) {
          this.isLoading = false
          this.dontReload = true
          this.$toastDanger(null, 'There are duplicated links')

          reject()
          return
        }
 
        this.formData.append('form_data', JSON.stringify(this.businessCard))
  
        const headers = {
          'Content-Type': 'multipart/form-data',
        }
  
        if (this.formType === 'create') {
          this.$http
            .post(
              `/api/business-cards`,
              this.formData,
              {
                headers,
              },
            )
            .then(response => {
              const { success, message, output } = response.data
              if (success) {
                this.formData = new FormData()
                this.businessCard = output
                this.$toastSuccess('Operation Success', message)
    
                // const route = this.$router.resolve({ name: 'business-cards-payment-success', params: { id: this.businessCard.id } }).href
                // this.stripeReturnUrl = new URL(route, window.location.origin).href
    
                // this.formType = 'edit'
                // this.cardId = this.businessCard.id
  
                this.isLoading = false
                this.dontReload = false
  
                this.$refs.refBusinessCardWizard.nextTab()
                this.$emit('refresh-record')

                resolve()
              }
            })
            .catch(error => {
              this.$handleResponseError(error)
              this.isLoading = false

              reject()
            })
        } else if (this.formType === 'edit') {
          this.$http
            .put(
              `/api/business-cards/${this.businessCard.id}`,
              this.formData,
              {
                headers,
              }
            )
            .then(response => {
              const { success, message, output } = response.data
              if (success) {
                this.formData = new FormData()
                this.businessCard = output
                this.$toastSuccess('Operation Success', message)

                this.isLoading = false
                this.dontReload = false
  
                this.$emit('refresh-record')

                resolve()
              }
            })
            .catch(error => {
              this.$handleResponseError(error)
              this.isLoading = false

              reject()
            })
        }
      })
    },
    submit() {
      setTimeout(() => {
        this.$emit('close-modal')
        this.$emit('refresh-record')
      }, 300)
    },

    addAnotherStaticPageLink() {
      this.businessCard.static_page_details.links.push({ ...this.staticPageLinkValueTemplate })
    },
    removeStaticPageLink(index) {
      this.businessCard.static_page_details.links.splice(index, 1)
    },
    addAnotherDynamicLink() {
      this.businessCard.dynamic_link.push('')
    },
    removeDynamicLink(index) {
      this.businessCard.dynamic_link.splice(index, 1)
    },
    changeActiveLink(event, link) {
      this.businessCard.active_link = event.target.checked ? link : ''
    },
    syncActiveLink(refCheckbox, link) {
      if (this.$refs[refCheckbox][0].checked) {
        this.businessCard.active_link = link
      }
    },

    updateFrontImage(input) {
      const file = input.target.files[0]
      this.previewImage(file, 'refFrontImageImg')
      this.formData.append('front_image_file', file)

      this.flipCard(false)
    },
    updateBackImage(input) {
      const file = input.target.files[0]
      this.previewImage(file, 'refBackImageImg')
      this.formData.append('back_image_file', file)

      this.flipCard(true)
    },
    updateStaticPageUserImage(input) {
      const file = input.target.files[0]
      this.previewImage(file, 'refStaticPageUserImageImg')
      this.formData.append('static_page_user_image', file)
    },
    previewImage(file, imgRef) {
      const image = file
      const reader = new FileReader()
      reader.readAsDataURL(image)
      reader.onload = e =>{
        this.$refs[imgRef].src = e.target.result
      };
    },

    // validate if can go to next step
    canGoAfterCardType() {
      const canGo = this.$isNotEmpty(this.businessCard.card_type_id)
      if (!canGo) {
        this.$toast(
          {
            component: this.$toastContent,
            props: {
              title: 'Unable to proceed',
              icon: 'AlertCircleIcon',
              text: 'Please select a Card Type',
              variant: 'danger',
            },
          },
          { timeout: this.$longestTimeout },
        )
      }

      return canGo
    },
    canGoAfterCardFinishing() {
      const canGo = this.$isNotEmpty(this.businessCard.card_finishing_id)
      if (!canGo) {
        this.$toast(
          {
            component: this.$toastContent,
            props: {
              title: 'Unable to proceed',
              icon: 'AlertCircleIcon',
              text: 'Please select a Card Finishing',
              variant: 'danger',
            },
          },
          { timeout: this.$longestTimeout },
        )
      }

      return canGo
    },
    createCard() {
      if (!this.isLoading) {
        // validate
        let proceed = true
        let errorMessage = ''
        if (this.businessCard.landing_page_type === 'dynamic') {
          this.businessCard.dynamic_link.forEach(link => {
            if (!this.$validateUrl(link)) {
              proceed = false
              // errorMessage += 'Please fill in the display name \n'
              if (link) {
                errorMessage += `${link} is not a valid link \n`
              } else {
                errorMessage += `Please provide a valid link \n`
              }
            }
          })
        } else {
          if (this.$isEmpty(this.businessCard.static_page_details.display_name)) {
            proceed = false
            errorMessage += 'Please fill in the display name \n'
          }
          this.businessCard.static_page_details.links.forEach(link => {
            if (!this.$validateUrl(link.redirected_link)) {
              proceed = false
              // errorMessage += 'Please fill in the display name \n'
              if (link.redirected_link) {
                errorMessage += `${link.redirected_link} is not a valid link \n`
              } else {
                errorMessage += `Please provide a valid link \n`
              }
            }
          })
        }

        if (!proceed) {
          this.isLoading = false
          this.dontReload = true
          this.$toastDanger('Invalid Form Value', 'Please correct or fill in a valid value.')

          return
        }

        if (this.$isNotEmpty(this.businessCard.id)) {
          this.formType = 'edit'
          // this.cardId = this.businessCard.id
          this.getBusinessCard(this.businessCard.id)
        }

        if (this.formType === 'create') {
          this.$bvModal
            .msgBoxConfirm(
              `Create card with the provided details?`,
              {
                title: 'Confirm Card Creation',
                size: 'sm',
                okVariant: 'success',
                okTitle: 'Create',
                cancelTitle: 'Cancel',
                cancelVariant: 'outline-secondary',
                hideHeaderClose: false,
                centered: true,
              },
            )
            .then(value => {
              if (value) {
                this.postBusinessCard()
                // this.$refs.refBusinessCardWizard.nextTab()
              }
            })
        } else {
          // let canGo = true
					// if (!this.firstTimeCreate) {
					// 	return this.postBusinessCard()
					// 		.then(() => {
					// 			this.firstTimeCreate = false
					// 			return true
					// 		})
					// } else {
					// 	return true
					// }
					return true
        }

      }

      return false
    },

    selectedCardTypeId(event) {
      this.businessCard.card_type_id = null
      this.businessCard.card_finishing_id = null
      setTimeout(() => {
        this.businessCard.card_type_id = event
      }, 100);
      // this.$set(this.businessCard, 'card_type_id', event)
      this.getBusinessCardType(event) // to check if needed to select finishing
    },
    selectedCardTypeFinishingId(event) {
      this.businessCard.card_finishing_id = event
    },

    flipCard(status = null) {
      if (status !== null) {
        this.displayCardBackSide = status
      } else {
        this.displayCardBackSide = !this.displayCardBackSide
      }
    },

  },
}
</script>

<style lang="scss" scoped>
.dynamic-link-input {
  // margin-top: 1em;
}

.card-display-container {
  height: 200px;
}

.vue-form-wizard .wizard-nav-pills a, .vue-form-wizard .wizard-nav-pills li{
  cursor: not-allowed;
  pointer-events: none;
}
</style>
