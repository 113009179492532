<template>
  <div>
    <b-row class="business-card-finishing-select">
      <b-col
        v-for="finishing in businessCardsTypeFinishings"
        :key="finishing.key"
        cols="12"
        md="3"
        class="d-flex flex-wrap align-items-center justify-content-center"
        style="gap: 1em;"
        @click="selectCardTypeFinishing(finishing)"
      >
        <div
          :ref="`business-card-type-finishing-${finishing.id}`"
          v-b-tooltip.hover.left="finishing.description"
          class="business-card-type border border-primary rounded p-2"
          :class="[
            {'selected' : selectedCardTypeFinishingId === finishing.id},
            {'disabled': $isNotEmpty(finishing.deactivated_at)},
            {'custom-overlay-danger custom-overlay-6': $isNotEmpty(finishing.deactivated_at)}
          ]"
        >
          <div class="d-flex flex-wrap justify-content-center">
            <img
              class="fade-in-image"
              fluid
              :src="finishing.image || $defaultCardTypeFinishingImage"
              width="90"
              height="90"
            >
          </div>
          <h4 class="text-primary text-center m-0 p-0 pt-1"> {{ finishing.name }} </h4>
          <h6 class="text-danger text-center m-0 p-0 pt-1 text-bolder text-unavailable">
            <b class="">
              {{ $isNotEmpty(finishing.deactivated_at) ? 'UNAVAILABLE' : ''}}
            </b>
          </h6>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: {
    cardTypeId: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      businessCardsTypeFinishings: [],
      selectedCardTypeFinishingId: null,
    }
  },
  created() {
    this.getBusinessCardsTypeFinishings(this.cardTypeId)
  },
  methods: {
    getBusinessCardsTypeFinishings(cardTypeId) {
      this.$http
        .get(`/api/business-cards-types/${cardTypeId}/finishings`)
        .then(response => {
          const { success, message, output } = response.data
          this.businessCardsTypeFinishings = output
        })
        .catch(error => {
          this.$handleResponseError(error)
        })
    },
    selectCardTypeFinishing(finishing) {
      if (this.$isEmpty(finishing.deactivated_at)) {
        this.$emit('selected-card-finishing-id', finishing.id)
        this.selectedCardTypeFinishingId = finishing.id
        // this.$refs[`business-card-type-finishing-${id}`][0].classList.value += (' selected')
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.business-card-finishing-select {
  max-height: 55vh;
  overflow-y: auto;
}

.business-card-type {
  // width: 8em;
  height: 12em;
  margin-block: 0.5em;
  margin-inline: 0.3em;

  &:hover{
    background-color: #bfa25e;

    h3, h4 {
      color: white !important;
    }
  }
}

.selected {
  background-color: #bfa25e;

  h3, h4 {
    color: white !important;
  }
}
.disabled {
  &:hover{
    background-color: grey;

    h3, h4 {
      color: black !important;
    }
  }

  .text-unavailable {
    color: #EA5455 !important;
  }
}

</style>
