<template>
  <div>
    <stripe-checkout
      v-if="$isNotEmpty(stripePublishableKey) && $isNotEmpty(stripeSessionId)"
      ref="checkoutRef"
      :pk="stripePublishableKey"
      :session-id="stripeSessionId"
      @loading="v => isLoading = v"
    />

    <!-- <stripe-checkout
      v-if="$isNotEmpty(stripePublishableKey)"
      ref="checkoutRef"
      mode="payment"
      :pk="stripePublishableKey"
      :line-items="lineItems"
      :success-url="successURL"
      :cancel-url="cancelURL"
      @loading="v => loading = v"
    /> -->
    <b-button @click="submit" class="mx-1" variant="primary">
      <h6 class="text-light m-0 p-0">
        Complete Payment
      </h6>
    </b-button>
  </div>
</template>

<script>
import { StripeCheckout } from '@vue-stripe/vue-stripe';
export default {
  components: {
    StripeCheckout,
  },
  props: {
    createSessionEndpoint: {
      type: String,
      default: null,
    },
    stripeReturnUrl: {
      type: String,
      default: null,
    },
    stripeCancelUrl: {
      type: String,
      default: null,
    },
		parentIsLoading: {
      type: Boolean,
      default: true,
		},
  },
  data() {
    this.publishableKey = process.env.STRIPE_PUBLISHABLE_KEY;
    return {
      isLoading: false,
      stripeSessionId: null,

      stripePublishableKey: null,
      clientSecret: null,
      lineItems: [
        {
          price: 'price_1MtYUdECCuXqKKn7aqQesdX6', // The id of the one-time price you created in your Stripe dashboard
          quantity: 1,
        },
      ],
      successURL: null,
      cancelURL: null,
      confirmParams: {
        return_url: null,
      }
    };
  },
  watch: {
    isLoading(val, oldVal) {
      this.$emit('is-loading', val)
    },
  },
  created() {
    // this.confirmParams.return_url = this.stripeReturnUrl

    this.getStripeConfig()
    const route = this.$router.resolve({name: 'business-cards-list'}).href
    this.successURL = new URL(route, window.location.origin).href
		this.successURL += '?stripe_status=success'
    this.cancelURL = new URL(route, window.location.origin).href
		this.cancelURL += '?stripe_status=failed'
    // this.postCreateStripeSession()
  },
  methods: {
    getStripeConfig() {
      this.$http
        .get(`/api/stripe-config`)
        .then(response => {
          const { success, message, output } = response.data
          this.stripePublishableKey = output.stripePublishableKey
        })
        .catch(error => {
          this.$handleResponseError(error)
        })
    },

    postCreateStripeSession() {
			return new Promise((resolve, reject) => {
				this.isLoading = true

				const formData = new FormData()
				formData.append('form_data', JSON.stringify(
					{
						line_items: this.lineItems,
						return_url: this.successURL,
						cancel_url: this.cancelURL,
					}
				))

				if (!this.$isEmpty(this.createSessionEndpoint)) {
					this.$http
						.post(this.createSessionEndpoint, formData)
						.then(response => {
							const { success, message, output } = response.data
							// this.stripeSessionId = output.stripe_session_id
							this.$set(this, 'stripeSessionId', output.stripe_session_id)
							// this.clientSecret = output.clientSecret
							// this.elementsOptions.clientSecret = this.clientSecret

							this.isLoading = false
							resolve()
						})
						.catch(error => {
							this.$handleResponseError(error)
							this.isLoading = false
							reject()
						})
				}
			})
    },

    submit() {
      // You will be redirected to Stripe's secure checkout page
			this.postCreateStripeSession()
				.then(() => {
					this.$refs.checkoutRef.redirectToCheckout();
				})
    },
  },
};
</script>